<template>
  <el-container>
    <el-aside width="713px">
      <el-container>
        <el-header height="77px">
          <el-form :inline="true">
            <el-form-item label="时间：">
              <el-select v-model="queryParam.SearchField">
                <el-option value="ActualTime" :label="queryParam.isRefund ? '出库时间' : '入库时间'"></el-option>
                <el-option value="CreateTime" :label="queryParam.isRefund ? '退货时间' : '采购时间'"></el-option>
              </el-select>
              <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期"
                value-format="yyyy-MM-dd" class="date-range" range-separator="至" :editable="false" :clearable="true"
                @change="handleQuery()"></el-date-picker>
            </el-form-item>
            <el-form-item label="供应商：">
              <el-input v-model="queryParam.SupplierName" placeholder="请输入供应商" @change="handleQuery()"></el-input>
            </el-form-item>
            <el-form-item label="商品状态：">
              <el-radio-group v-model="queryParam.Status" @change="handleQuery()">
                <el-radio :label="-1">全部</el-radio>
                <el-radio :label="0">待{{ queryParam.isRefund ? '出' : '入' }}库</el-radio>
                <el-radio :label="1">已{{ queryParam.isRefund ? '出' : '入' }}库</el-radio>
                <el-radio :label="2">已完成</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleQuery()">查询</el-button>
              <el-button type="default" @click="reset()">重置</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="toAddDetail()">创建{{ queryParam.isRefund ? '退货' : '采购' }}单</el-button>
              <el-button type="primary" @click="handleExport(0)" :loading="batchExportLoading">导出Excel</el-button>
            </el-form-item>
          </el-form>
        </el-header>
        <el-main>
          <grid-table ref="gridTable" :showIndexColumn="false" :showCheckboxColumn="false" :showLastEmptyColumn="false"
            :showPagination="true" :tableConfig="tableConfig" @handleRowClick="handleRowClick">
          </grid-table>
        </el-main>
      </el-container>
    </el-aside>
    <el-main>
      <el-container>
        <el-header height="42px">
          <el-form :inline="true" :model="detail.main" label-suffix="：">
            <el-form-item prop="SupplierName" label="供应商"
              :rules="detail.main.GoodStatus != 2 ? [{ required: true, message: '请输入供应商', trigger: 'blur' }] : []">
              <el-autocomplete placeholder="请输入供应商" v-model.trim="detail.main.SupplierName" value-key="SupplierName"
                style="width: 120px" :fetch-suggestions="loadSuppliers" @change="detail.isUpdate = true"
                v-if="detail.main.GoodStatus == 0" />
              <span v-else class="span-name">{{ detail.main.SupplierName }}</span>
            </el-form-item>
            <el-form-item v-if="$store.state.routes.systemSetting.MultiWarehouse" label="仓库">
              <warehouse-select v-if="detail.main.GoodStatus == 0" :value.sync="detail.main.WID"
                select_style="width:150px;" :set_default="true" @handleChanged="handleWarehouseChanged" />
              <span v-else>
                {{ detail.main.WName ? detail.main.WName : '默认仓库' }}
              </span>
            </el-form-item>
            <el-form-item :label="queryParam.isRefund ? '退货合计' : '采购合计'">
              <span class="num">{{ detail.main.PurchaseNum ? detail.main.PurchaseNum : 0 }}</span>件，货号合计：<span
                class="num">{{ detail.totalItemNum ? detail.totalItemNum : 0 }}</span>个，{{ queryParam.isRefund ? '出库合计'
            : '入库合计' }}：<span class="num">{{ detail.main.ActualNum ? detail.main.ActualNum : 0 }}</span>件
            </el-form-item>
          </el-form>
        </el-header>
        <el-main>
          <grid-table ref="detailTable" class="detail-table" :showIndexColumn="false" :showCheckboxColumn="false"
            :showPagination="false" :showLastEmptyColumn="false" :tableConfig="detailConfig"
            @handleCheckDisable="handleCheckDisable" @handleInputChange="handleInputChange">
            <template slot="PurchaseNumHeader" slot-scope="{}">
              <span :style="{ 'line-height': detail.main.CheckStatus == 0 ? '32px' : '' }">{{ queryParam.isRefund ?
            '退货数量' : '采购数量' }}</span>
              <el-tooltip content="批量设置数量" placement="top-start" v-if="detail.main.CheckStatus == 0">
                <el-input-number :controls="false" class="batch-num" :min="0" v-model="batchSetNum"
                  @change="handleBatchNum" />
              </el-tooltip>
            </template>
            <template slot="ActualNumHeader" slot-scope="{}">
              <span>{{ queryParam.isRefund ? '出库数量' : '入库数量' }}</span>
              <el-button class="table-button" type="primary" @click="allInStock" v-if="detail.main.CheckStatus == 0"
                size="mini">{{ queryParam.isRefund ? '全部出库' : '全部入库' }}</el-button>
            </template>
            <template slot="operation" slot-scope="{ params }">
              <i class="el-icon-delete" v-if="params.row.OriginActualNum == 0 && detail.main.CheckStatus != 1"
                @click="deleteDetail(params.row, params.index)"></i>
            </template>
          </grid-table>
        </el-main>
        <el-footer height="40px">
          <div class="div-button-left">
            <el-button type="default" @click="showAddGoodDlg()" v-if="detail.main.CheckStatus == 0">新增商品</el-button>
            <el-button type="danger" @click="deletePurchase()"
              v-if="detail.main.PurchaseID != 0 && detail.main.GoodStatus == 0">删除</el-button>
            <el-button type="success" @click="handleSavePurchase()" :loading="detail.loading"
              v-if="detail.main.CheckStatus == 0">保存</el-button>
            <el-button type="primary" @click="handleSavePurchase('inStock')" :loading="detail.loading"
              v-if="detail.main.CheckStatus == 0">确认{{ queryParam.isRefund ? '出库' : '入库'
              }}</el-button>
            <el-button type="warning" @click="handleCompletePurchase()" :loading="detail.loading"
              v-if="detail.main.PurchaseID != 0 && detail.main.CheckStatus == 0 && detail.main.GoodStatus > 0">完结</el-button>
            <el-upload ref="uploadXls" accept=".xls,.xlxs"
              :action="xlsData.uploadUrl + '?PurchaseID=' + detail.main.PurchaseID" :show-file-list="false"
              class="div-upload" :headers="xlsData.headers" :on-success="uploadXlsSuccess" :multiple="false"
              :on-error="uploadXlsError" :before-upload="uploadXlsBefore"
              v-if="detail.main.PurchaseID != 0 && detail.main.CheckStatus == 0">
              <el-button type="default" plain>导入{{ queryParam.isRefund ? '出库' : '入库' }}数量</el-button>
            </el-upload>
          </div>
          <div class="div-button-right">
            <el-button type="default" @click="handleExport(1)" v-if="detail.main.PurchaseID != 0"
              :loading="detail.loading">导出Excel</el-button>
            <el-button type="default" @click="handleCopy">复制报单</el-button>
          </div>
        </el-footer>
      </el-container>
    </el-main>
    <add-goods ref="addGoodsDlg" @handleSave="handleAddGoods"></add-goods>
  </el-container>
</template>

<script>
import { submit, exportFile } from "@/api/base";
import { getDate } from '@/utils/tool';
import { baseURL, successCode } from "@/config";
import AddGoods from "@/views/purchase/components/AddGoods.vue";
import WarehouseSelect from "@/views/components/common/WarehouseSelect.vue";
import dayjs from "dayjs";
import clipboard from "@/utils/clipboard";

export default {
  name: "pageList",
  components: {
    AddGoods,
    WarehouseSelect
  },
  watch: {
    $route: {
      handler: 'setPageQuery',
    }
  },
  created() {
    this.setPageQuery();
  },
  data() {
    return {
      routeParam: {},
      dateRange: [],
      queryParam: {
        isRefund: false,
        SearchField: "CreateTime",
        SupplierName: "",
        Status: -1,
        StartTime: '',
        EndTime: ''
      },
      tableConfig: {
        url: "/api/purchase/getPageList",
        rowKey: "PurchaseID",
        columns: [
          {
            label: "供应商",
            prop: "SupplierName",
            type: "text",
            width: "100px",
          },
          {
            label: "采购日期",
            prop: "CreateTime",
            type: "text-datetime",
            dateFormat: '<strong>yy-MM-dd</strong> HH:mm',
            width: "125px",
          },
          {
            label: "状态",
            prop: "GoodStatusStr",
            type: "text",
            width: "78px",
          },
          {
            label: '仓库',
            prop: 'WName',
            type: 'text',
            isHidden: !this.$store.state.routes.systemSetting.MultiWarehouse,
          },
          {
            label: "采购数量",
            prop: "PurchaseNum",
            type: "text",
          },
          {
            label: "入库数量",
            prop: "ActualNum",
            type: "text",
          },
          {
            label: "入库日期",
            prop: "ActualTime",
            type: "text-datetime",
            dateFormat: '<strong>yy-MM-dd</strong> HH:mm',
            width: "125px",
          },
        ],
      },
      detail: {
        isUpdate: false,//修改
        loading: false,
        main: { PurchaseID: 0, SupplierName: '', GoodStatus: 0, PurchaseNum: 0, CheckStatus: 0 },
        totalItemNum: 0,
      },
      detailConfig: {
        url: "/api/purchase/getDetails",
        columns: [
          {
            label: "货号",
            prop: "ItemNo",
            type: "text",
            width: "180",
            filter: true
          },
          {
            label: "颜色",
            prop: "Color",
            type: "text",
            width: "180",
            filter: true
          },
          {
            label: "尺码",
            prop: "Size",
            type: "text",
            width: "180",
            filter: true
          },
          {
            label: "采购数量",
            prop: "PurchaseNum",
            headerCustom: true,
            type: "input-int",
            width: "190",
            minProp: 'ActualNum',
            method: 'handleInputChange',
            checkDisableMethod: 'handleCheckDisable'
          },
          {
            label: "入库数量",
            prop: "ActualNum",
            headerCustom: true,
            type: "input-int",
            width: "180",
            method: 'handleInputChange',
            checkDisableMethod: 'handleCheckDisable'
          },
          {
            label: "操作",
            prop: "operation",
            type: "custom-operation",
            isHidden: false,
          },
        ],
      },
      suppliers: [],
      batchExportLoading: false,
      batchSetNum: 0,
      selectRow: null,
      xlsData: {
        loading: null,
        uploadUrl: `${baseURL}/api/purchase/importPurchaseDetail`,
        headers: {
          Authorization: "Bearer " + this.$store.getters["user/accessToken"],
        },
      },
      isRefund: false
    };
  },
  mounted() {
    this.$nextTick(async () => {
      const today = getDate();
      this.dateRange = [today, today];
      this.handleQuery();
      this.fetchSuppliers();
    });
  },
  methods: {
    handleWarehouseChanged(data) {
      this.detail.main.WName = data != null ? data.WName : '';
    },
    setPageQuery() {
      let arr = this.$route.fullPath.split('/');
      if (arr[arr.length - 1] == 'refund') {
        this.queryParam.isRefund = true;
        this.tableConfig.columns.forEach(column => {
          column.label = column.label.replace('采购', '退货').replace('入库', '出库');
        });
      }
      else {
        this.queryParam.isRefund = false;
      }
    },
    reset() {
      this.queryParam.SupplierName = "";
      this.queryParam.Status = -1;
      this.handleQuery();
    },
    //加载供应商
    async fetchSuppliers() {
      const { data } = await submit('/api/purchase/supplier/getList', {
        CurrentPage: 1,
        PageSize: 1000,
      });
      this.suppliers = data.datas || [];
    },
    async loadSuppliers(qs, cb) {
      const suppliers = this.suppliers;
      var results = qs ? suppliers.filter(t => t.SupplierName.toLowerCase().includes(qs.toLowerCase())) : suppliers;
      cb(results || []);
    },
    removeEditData() {
      this.selectRow = null;
      this.detail.main = { PurchaseID: 0, SupplierName: '', GoodStatus: 0, CheckStatus: 0, PurchaseNum: 0, PurchaseType: this.queryParam.isRefund ? 1 : 0 };
      this.detail.totalItemNum = 0;
      this.detail.isUpdate = false;
      this.batchSetNum = 0;
      this.$refs.detailTable.setData([]);
    },
    async handleQuery(purchaseId = 0) {
      this.removeEditData();
      this.queryParam.StartTime = '';
      this.queryParam.EndTime = '';
      if (this.dateRange && this.dateRange.length > 0) {
        this.queryParam.StartTime = this.dateRange[0];
        this.queryParam.EndTime = this.dateRange[1];
      }
      await this.$refs.gridTable.fetchData(false, null, this.queryParam);
      if (purchaseId > 0) {
        let rows = this.$refs.gridTable.tableData.filter(x => x.PurchaseID == purchaseId);
        if (rows.length > 0) {
          this.rowClick(rows[0]);
        }
      }
    },
    handleRowClick(row) {
      if (this.detail.isUpdate) {
        var that = this;
        this.$confirm(`当前采购${this.queryParam.isRefund ? '退货' : ''}单存在变动尚未保存，点击确定将丢失变动过的数据，是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          that.rowClick(row);
        }).catch(() => {
          //取消
        });
      } else {
        this.rowClick(row);
      }
    },
    async rowClick(row) {
      this.$refs.gridTable.$refs.gridTable.clearSelection();
      this.removeEditData();
      this.$refs.gridTable.$refs.gridTable.toggleRowSelection(row);
      this.detail.loading = true;//防止单个采购单太大或电脑性能问题导致太久没反应
      if (row.CheckStatus == 1) {
        this.detailConfig.columns[this.detailConfig.columns.length - 1].isHidden = true;
      }
      else {
        this.detailConfig.columns[this.detailConfig.columns.length - 1].isHidden = false;
      }
      const { data } = await submit(this.detailConfig.url, { PurchaseID: row.PurchaseID });
      this.detail.main = data.main;
      this.detail.main.OriginActualNum = this.detail.main.ActualNum;
      this.detail.totalItemNum = data.totalItemNum;
      this.$refs.detailTable.setData(data.details);
      this.detail.loading = false;
    },
    handleAddGoods(data) {
      if (!this.$refs.detailTable.tableData.some(t => t.ProductID == data.ProductID)) {
        this.detail.totalItemNum += 1;
      }
      for (let color in data.Colors) {
        for (let i = 0; i < data.Colors[color].Details.length; i++) {
          if (!this.$refs.detailTable.tableData.some(t => t.Color == color && t.Size == data.Colors[color].Details[i].Size)) {
            this.$refs.detailTable.tableData.push({
              PurchaseDetailID: 0,
              PurchaseID: this.detail.main.PurchaseID,
              ProductID: data.Colors[color].Details[i].ProductID,
              ItemNo: data.ItemNo,
              SpecID: data.Colors[color].Details[i].SpecID,
              SpecName: color + '-' + data.Colors[color].Details[i].Size,
              Color: color,
              Size: data.Colors[color].Details[i].Size,
              PurchaseNum: 0,
              ActualNum: 0,
            });
            this.$refs.detailTable.setRow(this.$refs.detailTable.tableData.length - 1);
          }
        }
      }
      //排序
      this.detail.isUpdate = true;
      this.batchSetNum = 0;
    },
    showAddGoodDlg() {
      this.$refs.addGoodsDlg.init(false, `新增采购${this.queryParam.isRefund ? '退货' : ''}商品`);
    },
    handleInputChange(rowIndex, value, prop) {
      let row = this.$refs.detailTable.tableData[rowIndex];
      if (row[prop] != value) {
        row[prop] = value;
      }
      let changNum = 0;
      if (row[prop] != row['Old' + prop]) {
        changNum = row[prop] - row['Old' + prop];
        row['Old' + prop] = row[prop];
        this.detail.isUpdate = true;
      }
      if (changNum == 0) {
        return;
      }
      this.detail.main[prop] += changNum;
    },
    handleCheckDisable(row, callback) {
      if (this.detail.main.CheckStatus == 0) {
        callback(false);
      }
      else {
        callback(true);
      }
    },
    deleteDetail(row, index) {
      if (row.OriginActualNum > 0) {
        return;
      }
      this.handleInputChange(index, 0, 'PurchaseNum');
      this.handleInputChange(index, 0, 'ActualNum');
      this.$refs.detailTable.tableData.splice(index, 1);
    },
    allInStock() {
      this.$refs.detailTable.tableData.forEach((detail, index) => {
        if (detail.PurchaseNum > detail.ActualNum) {
          this.handleInputChange(index, detail.PurchaseNum, 'ActualNum');
        }
      });
    },
    handleBatchNum() {
      if (this.batchSetNum || this.batchSetNum == 0) {
        this.$refs.detailTable.tableData.forEach((detail, index) => {
          this.handleInputChange(index, this.batchSetNum > detail.OriginActualNum ? this.batchSetNum : detail.ActualNum, 'PurchaseNum');
        });
      }
    },
    deletePurchase() {
      if (this.detail.main.PurchaseID <= 0) {
        return;
      }
      var that = this;
      this.$confirm(`您是否要删除当前采购${this.queryParam.isRefund ? '退货' : ''}单?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const result = await submit(`/api/purchase/delete`, { PurchaseID: that.detail.main.PurchaseID });
        that.removeEditData();
        that.handleQuery();
      }).catch(() => {
        //取消          
      });
    },
    toAddDetail() {
      var that = this;
      if (this.detail.isUpdate) {
        that.$confirm(`当前采购${this.queryParam.isRefund ? '退货' : ''}单存在变动尚未保存，是否继续创建新的采购${this.queryParam.isRefund ? '退货' : ''}单?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          that.removeEditData();
        }).catch(() => {
          //取消          
        });
      } else {
        that.removeEditData();
      }
    },
    handleCompletePurchase() {
      var that = this;
      if (this.detail.isUpdate) {
        that.$confirm(`当前采购${this.queryParam.isRefund ? '退货' : ''}单存在变动尚未保存，直接完结会导致数据丢失，是否继续执行 完结 操作？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          that.handleComplete();
        }).catch(() => {
          //取消          
        });
      } else {
        that.handleComplete();
      }
    },
    async handleComplete() {
      var that = this;
      that.detail.loading = true;
      const { data } = await submit(`/api/purchase/complete`, { PurchaseID: that.detail.main.PurchaseID }).catch((error) => {
        that.detail.loading = false;
      });
      that.detail.loading = false;
      if (data.errorMsg) {
        that.$message({ type: "error", message: data.errorMsg });
        return;
      }
      that.$message({ type: "success", message: "操作成功!" });
      that.handleQuery(data.PurchaseID);
    },
    async handleSavePurchase(type) {
      //验证数据
      var param = { ...this.detail.main };
      param.DetailList = [];
      if (this.detail.isUpdate) {
        if (param.SupplierName == '') {
          this.$baseMessage(`请输入供应商`, "error");
          return;
        }
        if (this.$refs.detailTable.tableData.length <= 0) {
          this.$baseMessage(`请选择要${this.queryParam.isRefund ? '退货' : '采购'}的商品`, "error");
          return;
        }
      }
      var errorList = [];
      var isInStock = false;
      this.$refs.detailTable.tableData.forEach(x => {
        if (x.ActualNum > x.OriginActualNum) {
          isInStock = true;
        }
        let tempY = { ...x };
        tempY.ActualNum = tempY.ActualNum - tempY.OriginActualNum;
        param.DetailList.push(tempY);
      });
      if (!isInStock && type == "inStock") {
        errorList.push(`${this.queryParam.isRefund ? '出' : '入'}库数量不能为空`);
      }
      if (errorList.length > 0) {
        this.$baseMessage(errorList.join('<br/>'), "error");
        return;
      }
      //提交
      if (type == 'inStock') {
        var that = this;
        that.$confirm(`当前采购${this.queryParam.isRefund ? '退货' : ''}单已${this.queryParam.isRefund ? '出' : '入'}库 ${that.detail.main.OriginActualNum} 件，当前待${this.queryParam.isRefund ? '出' : '入'}库 ${(that.detail.main.ActualNum - that.detail.main.OriginActualNum)} 件，确认${this.queryParam.isRefund ? '出' : '入'}库?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          that.handleInStock(param);
        }).catch(() => {
          //取消          
        });
      } else {
        this.handleSave(param);
      }
    },
    async handleSave(param, only = true) {
      this.detail.loading = true;
      const { data } = await submit(`/api/purchase/save`, param);
      this.detail.loading = false;
      if (data.errorMsg) {
        this.$message({ type: "error", message: data.errorMsg });
        return false;
      }
      if (only) {
        this.$message({ type: "success", message: "保存成功!" });
        this.handleQuery(data.PurchaseID);
      }
      return true;
    },
    async handleInStock(param) {
      if (this.detail.isUpdate) {
        let result = await this.handleSave(param, false);
        if (!result) {
          return;
        }
      }
      this.detail.loading = true;
      const { data } = await submit(`/api/purchase/inStock`, param);
      this.detail.loading = false;
      if (data.errorMsg) {
        this.$message({ type: "error", message: data.errorMsg });
        return;
      }
      this.$message({ type: "success", message: `${this.queryParam.isRefund ? '出' : '入'}库成功!` });
      this.handleQuery(data.PurchaseID);
    },
    async handleExport(type) {//0列表导出；1编辑的采购单
      let param = {};
      var that = this;
      if (type == 0) {
        param = { ...this.queryParam };
        this.batchExportLoading = true;
      } else {
        if (this.detail.main.PurchaseID == 0) {
          this.$baseMessage(`请选择要导出的数据`, "error");
          return;
        }
        param = { PurchaseID: this.detail.main.PurchaseID, isRefund: this.queryParam.isRefund };
        this.detail.loading = true;
      }
      const today = dayjs().startOf('day').format('YYYYMMDD');
      const { data } = await exportFile('/api/purchase/export', param);
      const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = `采购${this.queryParam.isRefund ? '退货' : ''}单${type == 1 ? ('_' + this.detail.main.SupplierName) : ''}_${today}.xls`;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
      if (type == 0) {
        that.batchExportLoading = false;
      } else {
        that.detail.loading = false;
      }
    },
    uploadXlsSuccess(res) {
      if (successCode.includes(res.code)) {
        this.$message({ type: "success", message: res.msg });
      } else {
        this.$message({ type: "error", message: "导入错误：" + res.msg });
      }
      this.$refs.uploadXls.clearFiles();
      this.handleQuery(this.detail.main.PurchaseID);
      if (this.xlsData.loading != null) {
        this.xlsData.loading.close();
      }
    },
    uploadXlsError() {
      this.$message({ type: "error", message: "导入失败" });
      if (this.xlsData.loading != null) {
        this.xlsData.loading.close();
      }
    },
    uploadXlsBefore(file) {
      this.xlsData.loading = this.$loading({
        lock: true,
        text: "正在导入中......"
      });
    },
    handleCopy(e) {
      if (this.$refs.detailTable.tableData.length <= 0) {
        this.$baseMessage(`没有可复制的商品`, "error");
        return;
      }
      var arr = {};
      this.$refs.detailTable.tableData.forEach(x => {
        if (typeof (arr[x.ItemNo]) == 'undefined') {
          arr[x.ItemNo] = {};
        }
        var colorItem = "";
        var num = x.PurchaseNum;
        if (num > 0) {
          colorItem += x.Size + '*' + num;
        }
        if (colorItem != '') {
          if (typeof (arr[x.ItemNo][x.Color]) == 'undefined') {
            arr[x.ItemNo][x.Color] = [];
          }
          arr[x.ItemNo][x.Color].push(colorItem);
        }
      });
      var text = '';
      for (var key in arr) {
        let itemText = '';
        for (var color in arr[key]) {
          if (arr[key][color].length > 0) {
            itemText += color + arr[key][color].join(' ');
          }
        }
        if (itemText) {
          text += key + itemText + "\r\n";
        }
      }
      if (text == '') {
        this.$baseMessage(`没有可复制的商品，请商品数量大于0`, "error");
      } else {
        clipboard(text, e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-aside {
    .el-header {
      .el-form {
        .el-form-item {
          margin-bottom: 5px !important;
        }
      }

      .el-select {
        width: 100px;
        margin-right: 5px;
      }

      .date-range {
        width: 210px;

        .el-range-input {
          width: 70px;
        }
      }

      .el-radio {
        margin-right: 10px;
      }

      .el-form-item__label {
        padding-right: 0;
      }

    }
  }

  .el-main {
    .el-form {
      margin-left: 10px;

      .span-name {
        font-weight: bold;
        font-size: 20px;
        margin-right: 10px;
      }

      .el-form-item__label {
        padding-right: 0px !important;
        font-size: 16px;
      }

      .el-form-item__content {
        font-size: 16px;
      }
    }

    .detail-table {

      .el-icon-delete {
        color: #ff0000;
        cursor: pointer;
      }

      td .el-input-number {
        .el-input {
          width: 150px;
          font-size: 20px;

          .el-input__inner {
            text-align: center;
          }
        }
      }

      .batch-num {
        float: right;
        width: 60px;
      }

      .table-button {
        float: right;
        width: 60px;
        padding: 5px;

        .el-input__inner {
          padding: 0;
        }
      }
    }

    .num {
      margin-right: 5px;
      font-size: 20px;
      font-weight: bold;
    }
  }

  &>footer {
    .div-button-left {
      display: inline-block;
      width: 50%;
      margin-top: 5px;

      .el-button,
      .div-upload {
        float: left;
        height: 30px;
        margin-left: 5px;
      }
    }

    .div-button-right {
      display: inline-block;
      width: 50%;
      margin-top: 4px;

      .el-button {
        float: right;
        margin-left: 5px;
      }
    }
  }

}
</style>
